import { collection, getDocs, getFirestore } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { IoCallOutline, IoLocationOutline, IoSearchSharp } from 'react-icons/io5'
import './StoreBranch.css'
import { StoreDetail } from '../App/App_Store_interface'
import { getAuth, signInAnonymously } from '@firebase/auth'
import { initializeApp } from 'firebase/app'

export default function StoreBranches() {

    const [stores, setStores] = useState<Array<StoreDetail>>([])

    const [chainId] = window.location.pathname.split('/').splice(1)

    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        initializeApp({
            apiKey: "AIzaSyA5P1_Fmfe3eFyrRaf6MHYU4Fr4dcyMDLU",
            authDomain: "onlinebooking-81b87.firebaseapp.com",
            projectId: "onlinebooking-81b87",
            storageBucket: "onlinebooking-81b87.appspot.com",
            messagingSenderId: "401401401576",
            appId: "1:401401401576:web:79e1eaace57f4d6e44395d",
            measurementId: "G-BGFDQCQHV5"
        })

        signInAnonymously(getAuth()).then(() => {
            getDocs(
                collection(
                    getFirestore(),
                    `chains/${chainId}/stores`,
                ),
            ).then((data) => {
                setStores(data.docs.map((d) => {
                    return {
                        ...d.data(),
                        id: d.id,
                    }
                }) as Array<StoreDetail>)
            })
        })
    }, [chainId])

    if (stores.length === 0) {
        return <div className='loader'></div>
    }


    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100vw'
            }}>
                <img src={stores[0].logo} alt="store logo" width={172} style={{
                    borderRadius: '14px'
                }} />
            </div>
            <small style={{ padding: '22px' }}>Choose a location</small>

            <div id='searchBar' className='canvas radiusMax'
                style={{
                    margin: '0 14px'
                }} >
                <IoSearchSharp size={'22'} />
                <input
                    onChange={async e => setSearchQuery(e.target.value)}
                    type='text'
                    placeholder='search by name or location...' />
            </div>

            <div id='StoreBranch'>
                {stores
                    .filter(store => {
                        if (searchQuery.length === 0) {
                            return true;
                        }
                        const searchLowerString = searchQuery.toLowerCase()

                        return store.name.toLowerCase().startsWith(searchLowerString) ||
                            store.address.line1.toLowerCase().includes(searchLowerString) ||
                            store.address.line2.toLowerCase().includes(searchLowerString) ||
                            store.address.line1.toLowerCase().startsWith(searchLowerString) ||
                            store.address.line2.toLowerCase().startsWith(searchLowerString)
                    })
                    .map((store, i) => (
                        <div
                            key={i}
                            className='storeBranchCard canvas'
                        >
                            <img
                                src={store.logo ?? ''}
                                alt='StoreImage'
                                width={142}
                            />
                            <h2> {store.name} </h2>
                            <a
                                href={`https://www.google.com/maps/@${store.address.lat},${store.address.long},${17}z`}
                                className='branchCardAddress'
                            >
                                <IoLocationOutline size={'32'} style={{
                                    gridRow: '1 / span 2',
                                    border: '0.7px solid skyBlue',
                                    borderRadius: '7px',
                                    padding: '4px',
                                }} />
                                <p> {store!.address.line1}</p> <br />
                                <p>{store.address.line2 ?? ''}</p>
                            </a>
                            {store.contactInfo.phoneNumber !== undefined &&
                                store.contactInfo.phoneNumber !== null &&
                                <a
                                    href={`tel://${store.contactInfo.phoneNumber}`}
                                    className='branchCardAddress'
                                >
                                    <IoCallOutline size={'32'} style={{
                                        gridRow: '1 / span 2',
                                        border: '0.7px solid skyBlue',
                                        borderRadius: '7px',
                                        padding: '4px',
                                    }} />
                                    <p> {store!.contactInfo.phoneNumber}</p>
                                </a>
                            }
                            <a
                                aria-label='Change Brach'
                                className='canvasDark'
                                style={{
                                    padding: '7px 14px',
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: '500',
                                    marginBottom: '14px',
                                }}
                                href={`https://${window.location.host}/${chainId}/${store.id}/${store.bookingMedia.length === 0 ? 'retail-booking' : 'booking'}`}
                            >
                                Book Now
                            </a>
                            <div id='storeMedia'>
                                {(
                                    store.bookingMedia.length !== 0 ? store.bookingMedia : store.retailBookingMedia
                                ).slice(0, 3).map((img, i) => {
                                    return <img
                                        key={i}
                                        src={img}
                                        alt='StoreImage'
                                        height={142}
                                    ></img>
                                })}
                            </div>
                        </div>

                    ))}
            </div >
        </>
    )
}
