import './App.css';

import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react';
import SlideDrawer from '../SlideDrawer/SlideDrawer';
import SalonCard from '../salonCard/SalonCard';
import CarouselSlider from '../CarouselSlider/CarouselSlider';
import Cart, { CartButton } from '../Cart/Cart';

import { initializeApp } from "firebase/app";
import RemoveItemConform from '../Cart/RemoveItemConform';
import ConfirmBookingPage from '../SlideDrawer/ConfirmBookingPage';
import { removeItemFromCart, showBookingDrawer, showBookingSuccessMessageAtom, showSelectServices, showTermsAndConditions, storeDetailAtom } from './App_state';
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { Category, Employee, StoreDetail } from './App_Store_interface';
import Booking from '../Booking/Booking';
import CategoryGridView from '../CategoryGridView/CategoryGridView';
import GetInTouch from '../GetInTouch/GetInTouch';
import SearchBar from '../SearchBar/SearchBar';
import TermsAndConditionsDialog from '../SlideDrawer/TermsAndConditions';
import { getAuth, signInAnonymously } from '@firebase/auth';
import StoreBranches from '../StoreBranch/StoreBranch';
import { selectedEmployeeIdAtom } from '../Booking/Booking_state';


/// app entry point
export default function App(): JSX.Element {

  // state to toggle between select services and Booking Page 
  const [showSelectServicesState, setShowSelectServices] = useAtom(showSelectServices)

  // state to handle open and close booking form drawer
  const showBookingDrawerState = useAtomValue(showBookingDrawer)

  // state contain store detail and set at init of app  in useEffect
  const [storeDetail, setStoreDetail] = useAtom(storeDetailAtom)

  // if selected employee not present in store detail employees then set selected employee to first employee in store detail 
  const setSelectedStaff = useSetAtom(selectedEmployeeIdAtom)

  const showBookingSuccessMessage = useAtomValue(showBookingSuccessMessageAtom)

  // set item that have to remove from cart 
  // then show a dialog to remove that item from cart
  const removeItemDialogItem = useAtomValue(removeItemFromCart)

  const showTermsAndConditionsValue = useAtomValue(showTermsAndConditions)

  const [chainId, storeId, storeType] = window.location.pathname.split('/').splice(1)

  const isRetailStore = storeType === 'retail-booking'

  // fetch store detail from firestore by store id present in url path
  const fetchData = async () => {

    await signInAnonymously(getAuth())

    // get data from firestore async
    const [chainDoc, data, categories, employees] = await Promise.all([
      getDoc(
        doc(
          getFirestore(),
          'chains',
          chainId,
        ),
      ),
      getDoc(
        doc(
          getFirestore(),
          `chains/${chainId}/stores`,
          storeId,
        ),
      ),
      getDocs(
        query(
          collection(
            getFirestore(),
            `chains/${chainId}/stores/${storeId}/${isRetailStore ? 'productCategories' : 'serviceCategories'}`,
          ),
          where('isVisibleInFirestore', '==', true),
        )
      ),
      isRetailStore === false ? getDocs(
        query(
          collection(
            getFirestore(),
            `chains/${chainId}/stores/${storeId}/employees`,
          ),
          where('isVisibleInFirestore', '==', true),
        )
      ) : undefined,
    ])


    // convert and combine store data fetch from firestore
    let storeData = {
      ...data.data(),
      id: storeId,
      totalStore: chainDoc.get('totalStore'),
      chainName: chainDoc.get('name'),
      holidays: data.get('holidays').map((d: string) => new Date(Date.parse(d))),
      employees: employees?.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      }) as Employee[] | undefined,
      categories: categories.docs.map((s) => {
        return {
          ...s.data(),
          id: s.id,
        }
      }) as Category[],
    } as StoreDetail

    // sort employees by their first name
    storeData.employees?.sort((a, b) => a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0)

    // remove inActive employees and category, its important
    storeData.employees = storeData.employees?.filter((s) => s.isActive && s.isVisibleInFirestore)
    storeData.categories = storeData.categories.filter((c) => c.isActive && c.isVisibleInFirestore)


    // if selected employee not present in store detail employees then set selected employee to first employee in store detail 
    if (storeData?.defaultEmployeeId !== undefined && storeData?.defaultEmployeeId !== null) {
      const defaultEmployee: Employee = {
        id: storeData.defaultEmployeeId!.toString(),
        invoayId: storeData.defaultEmployeeId!,
        firstName: 'Default',
        lastName: 'Employee',
        isActive: true,
        isVisibleInFirestore: true,
      };

      storeData?.employees?.push(defaultEmployee)
      setSelectedStaff(defaultEmployee.id)

    } else if (storeData.employees !== undefined) {
      setSelectedStaff(storeData.employees[0].id)
    }

    // set Store detail to show store detail
    setStoreDetail(storeData)
  }

  useEffect(() => {
    // if (window.location.hostname === 'booking.worldskillcenter.org') {
    //   window.location.href = 'https://osdcbookingapp.invoay.com/WG5VXkYg5XwBnLdQPsuL/qfsQV3ohnvPtwlk3akrb'
    //   return;
    // }

    window.onhashchange = () => {
      const hash = window.location.hash
      if ((hash === '#Booking' || hash === '#appBar') && isRetailStore === false)
        return
      if (hash === '#slider-container' || hash === '#Cart')
        return
      else if (showSelectServicesState && (hash === '#Booking' || hash === '#appBar'))
        setShowSelectServices(false)
      else {
        setShowSelectServices(true)
      }
    }

    // init firebase default app
    initializeApp({
      apiKey: "AIzaSyA5P1_Fmfe3eFyrRaf6MHYU4Fr4dcyMDLU",
      authDomain: "onlinebooking-81b87.firebaseapp.com",
      projectId: "onlinebooking-81b87",
      storageBucket: "onlinebooking-81b87.appspot.com",
      messagingSenderId: "401401401576",
      appId: "1:401401401576:web:79e1eaace57f4d6e44395d",
      measurementId: "G-BGFDQCQHV5"
    })


    if (storeId !== undefined && window.location.pathname.split('/')[2] !== 'aboutUs') {
      fetchData()
    }



    // fetch store detail data only when store id present in url path 
    if (storeId !== undefined) {
      fetchData()
    }

  }, [chainId, isRetailStore])


  const [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia("(min-width: 972px)").matches);

  useEffect(() => {
    window.matchMedia("(min-width: 972px)")
      .addEventListener('change', (e) => setIsLargeScreen(e.matches))
  })

  if (showBookingSuccessMessage) {
    return <ConfirmBookingPage isRetailStore={isRetailStore} />
  }


  if (window.location.pathname === '/') {


    return <div id='invalidStoreMessage' className='canvas'>
      <img src='invoay_logo.png' alt='logo' width={300} />
      <h2>We are sorry,<br />we cant find this store. If you are interested for getting booking enabled for your store,<br />you can get in touch with us on <br /> <a aria-label='invoay url' href="https://www.invoay.com">www.invoay.com</a><br />We would love to have you on board.<br />See you soon.</h2>
    </div>
  }

  if (storeId === undefined) {
    return <StoreBranches />
  }

  // show loading screen when data i being loaded
  if (storeDetail === null) {
    return <div className='loader'></div>
  }

  // for inactive Store show inactive message
  if (storeDetail!.isActive === false) {
    return <h3>store is currently inactive</h3>
  }

  if (Date.parse(storeDetail!.expiryDate!) <= new Date().valueOf()) {
    return <div id='invalidStoreMessage' className='canvasDark'>
      <img src='invoay_logo.png' alt='logo' width={300} />
      <h2>Your Subscription has expired.<br />you can get in touch with us on <br /> <a aria-label='invoay url' href="https://www.invoay.com">www.invoay.com</a><br />We would love to have you on board.<br />See you soon.</h2>
    </div>
  }


  return (
    <>
      <SlideDrawer show={showBookingDrawerState} />

      {removeItemDialogItem && <RemoveItemConform item={removeItemDialogItem!} />}

      {showTermsAndConditionsValue && storeDetail.termsAndConditions && <TermsAndConditionsDialog termsAndConditions={storeDetail.termsAndConditions} />}

      {showTermsAndConditionsValue && <div className='backdrop' style={{
        zIndex: '242',
      }}></div>}

      {(showBookingDrawerState || removeItemDialogItem) && <div className='backdrop'></div>}

      <div id={isLargeScreen ? 'webView' : 'mobileView'}>
        <SalonCard storeDetail={storeDetail!} isRetailStore={isRetailStore} />
        <CarouselSlider images={isRetailStore ? storeDetail!.retailBookingMedia : storeDetail!.bookingMedia} />

        {showSelectServicesState && <SearchBar />}


        {showSelectServicesState ? <CategoryGridView /> : <Booking isRetailStore={isRetailStore} />}


        <Cart />
        <GetInTouch storeDetail={storeDetail} isLargeScreen={isLargeScreen} />

      </div>

      {!isLargeScreen && <CartButton />}
    </>
  )
}


